import { XingletLoader } from '@xing-com/crate-xinglet';

import { SimilarJobsSkeleton } from './similar-jobs-skeleton';

export const JobsSection: React.FC = () => {
  return (
    <XingletLoader
      name="@xing-com/crate-startpage-logged-out-jobs-section"
      fallback={<SimilarJobsSkeleton />}
    />
  );
};
