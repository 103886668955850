import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceS, spaceXXL, space4XL } from '@xing-com/tokens';

export const StyledSection = styled.section`
  margin-bottom: ${spaceXXL};

  @media ${mediaConfined} {
    margin-bottom: ${space4XL};
  }
`;

export const StyledAnchor = styled.a<{ isSerpActive: boolean }>`
  display: ${(props) => (props.isSerpActive ? 'inline-block' : 'none')};
  margin: 0 ${spaceS} ${spaceS} 0;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledSerpWrapper = styled.div`
  margin-top: ${spaceXXL};
`;
