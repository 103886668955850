import { SkeletonCardPreset } from '@xing-com/skeleton';

import { JobsSectionCarousel } from './jobs-section-carousel';

export const SimilarJobsSkeleton: React.FC = () => {
  return (
    <JobsSectionCarousel useDesktopVersion>
      {Array.from({ length: 5 }).map((_, index) => (
        <SkeletonCardPreset key={index} variant="profile" />
      ))}
    </JobsSectionCarousel>
  );
};
