import styled from 'styled-components';

import {
  mediaConfined,
  mediaNavExtended,
  mediaTiny,
} from '@xing-com/layout-tokens';
import {
  scale080,
  scale130,
  spaceM,
  spaceL,
  spaceXXL,
  space4XL,
  xdlPaletteWhite,
  scale140,
  xdlColorTextOnLight,
  xdlPalettePurpleRain05,
  space3XL,
} from '@xing-com/tokens';
import { Hero, Headline, BodyCopy } from '@xing-com/typography';
import { Butterfly } from '@xing-com/xing-logo';

import background from './assets/background.svg';
import illustration from './assets/illustration-native-app-section.jpg';

export const StyledContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: ${spaceXXL};
  margin-bottom: ${spaceXXL};
  gap: ${spaceL};

  @media ${mediaConfined} {
    margin-top: ${space4XL};
    margin-bottom: ${space4XL};
  }

  @media ${mediaNavExtended} {
    grid-template-columns: 9fr 3fr;
  }

  @media ${mediaTiny} {
    display: flex;
    flex-direction: column;
  }
`;

export const RightContainer = styled.div`
  height: 406px;
  border-radius: ${scale130};
  background-image: url(${illustration});
  background-position: center;
  background-size: cover;
  width: 100%;

  @media ${mediaTiny} {
    height: 200px;
  }
`;

export const LeftContainer = styled.div`
  background-color: ${xdlPalettePurpleRain05};
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  border-radius: ${scale130};
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  padding: ${spaceXXL};

  @media ${mediaConfined} {
    border-radius: ${scale130};
    height: 406px;
    text-align: left;
    justify-content: space-evenly;
    padding: ${space3XL};
  }
`;

export const QrCodeWrapper = styled.div`
  position: relative;
  display: none;

  @media ${mediaNavExtended} {
    margin-left: 0;
    display: flex;
    background: ${xdlPaletteWhite};
    border-radius: ${scale080};
    height: 286px;
    width: 286px;
  }
`;

export const StyledHero = styled(Hero)`
  color: ${xdlColorTextOnLight};
  margin-bottom: ${spaceL};
`;

export const StyledHeadline = styled(Headline)`
  color: ${xdlColorTextOnLight};
  margin-bottom: ${spaceL};
`;

export const StyledBodyCopy = styled(BodyCopy)`
  color: ${xdlColorTextOnLight};
  margin-bottom: ${spaceXXL};
`;

export const ServiceStoreImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceM};

  @media ${mediaConfined} {
    flex-direction: row;
    gap: ${spaceL};
  }
`;

export const ServiceStoreImage = styled.img`
  height: 70px;
  width: 210px;

  @media ${mediaConfined} {
    height: 48px;
    width: 144px;
  }
`;

export const TextContainer = styled.div`
  max-width: 330px;
`;

export const QRCodeContainer = styled.div`
  &&& {
    display: flex;
    align-items: center;
  }
`;

export const StyledButterfly = styled(Butterfly)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 53px;
`;

export const StyledQRCode = styled.img`
  padding: ${scale140};
`;
