import styled, { css } from 'styled-components';

import { AiSearchBanner } from '@xing-com/crate-jobs-domain-search-ai-search-banner';
import { IconReaction } from '@xing-com/icons';
import { Section } from '@xing-com/layout-primitives';
import { mediaConfined } from '@xing-com/layout-tokens';
import {
  spaceM,
  space4XL,
  xdlColorBackground,
  xdlColorText,
  xdlColorMarkerHighlightText,
  spaceXXL,
} from '@xing-com/tokens';

export const StyledCarouselSection = styled(Section)<{ $bgColor?: string }>`
  ${({ $bgColor }) => css`
    background: ${$bgColor || xdlColorBackground};
  `}

  padding-top: ${spaceXXL};
  padding-bottom: ${spaceXXL};

  @media ${mediaConfined} {
    padding-top: ${space4XL};
    padding-bottom: ${space4XL};
  }
`;

export const StyledAiSearchBanner = styled(AiSearchBanner)`
  margin: 0 auto ${spaceM};

  @media ${mediaConfined} {
    position: absolute;
    width: fit-content;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

export const StyledAnchor = styled.a`
  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: ${xdlColorText};
  }
`;

export const StyledIcon = styled(IconReaction)`
  color: ${xdlColorMarkerHighlightText};
`;
