import { useMediaListener, BREAKPOINTS } from '@xing-com/util';

import { StyledCarouselSection } from './jobs-section.styles';

type Props = {
  children: React.ReactNode;
  useDesktopVersion?: boolean;
};

export const JobsSectionCarousel: React.FC<Props> = ({
  children,
  useDesktopVersion,
}) => {
  const breakpoint = useMediaListener();
  const isHandheld = breakpoint === BREAKPOINTS.handheld;

  return (
    <StyledCarouselSection
      titleKey="LOSP_JOBS_SECTION_TITLE"
      subtitleKey="LOSP_JOBS_SECTION_SUBTITLE"
      itemWidth={isHandheld && !useDesktopVersion ? '80%' : '368px'}
      itemMinWidth={isHandheld && !useDesktopVersion ? '80%' : '368px'}
      itemMaxWidth={isHandheld && !useDesktopVersion ? '80%' : '368px'}
      useFullWidth
    >
      {children}
    </StyledCarouselSection>
  );
};
