import styled, { css } from 'styled-components';

import { scale005, xdlColorBorderSoft } from '@xing-com/tokens';

// *** Base ***
const baseDivider = css`
  background-color: ${xdlColorBorderSoft};
  border: none;
  clear: both;
  height: ${scale005};
  margin: 0;
  width: 100%;
`;

// *** Components ***
export const Divider = styled.hr`
  ${baseDivider}
`;
