import { useIntl } from 'react-intl';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { mediaConfined } from '@xing-com/layout-tokens';

import AppleStoreSvgDe from './assets/app-store-badge-de.svg';
import AppleStoreSvgEn from './assets/app-store-badge-en.svg';
import GooglePlaySvgDe from './assets/google-play-badge-de.svg';
import GooglePlaySvgEn from './assets/google-play-badge-en.svg';
import QrCodeSvg from './assets/qrcode.svg';
import {
  LeftContainer,
  TextContainer,
  QRCodeContainer,
  StyledHero,
  StyledBodyCopy,
  ServiceStoreImageWrapper,
  ServiceStoreImage,
  QrCodeWrapper,
  StyledButterfly,
  StyledQRCode,
  RightContainer,
  StyledContainer,
  StyledHeadline,
} from './native-apps-section.styles';

export const appleStoreUrl = 'https://apps.apple.com/de/app/xing/id297625850';
export const googlePlayUrl =
  'https://play.google.com/store/apps/details?id=com.xing.android';

type Props = {
  trackClickStoreButton: (action: string) => void;
};

export const NativeAppsSection: React.FC<Props> = ({
  trackClickStoreButton,
}) => {
  const { formatMessage, locale } = useIntl();
  const isMediaConfined = useMatchMedia(mediaConfined);

  return (
    <StyledContainer>
      <LeftContainer>
        <TextContainer>
          {isMediaConfined ? (
            <StyledHero forwardedAs="h2" size="small">
              {formatMessage({ id: 'QR_CODE_BANNER_TITLE' })}
            </StyledHero>
          ) : (
            <StyledHeadline size="xxlarge">
              {formatMessage({ id: 'QR_CODE_BANNER_TITLE' })}
            </StyledHeadline>
          )}
          <StyledBodyCopy size="medium" sizeConfined="large" forwardedAs="p">
            {formatMessage({ id: 'QR_CODE_BANNER_SUBTITLE' })}
          </StyledBodyCopy>
          <ServiceStoreImageWrapper>
            <a
              href={googlePlayUrl}
              data-qa={'google-store-badge'}
              onClick={() =>
                trackClickStoreButton('losp_download_android_app_cta_click')
              }
            >
              <ServiceStoreImage
                src={locale === 'de' ? GooglePlaySvgDe : GooglePlaySvgEn}
                alt="google-store-badge"
                loading="lazy"
              />
            </a>
            <a
              data-qa={'apple-store-badge'}
              href={appleStoreUrl}
              onClick={() =>
                trackClickStoreButton('losp_download_ios_app_cta_click')
              }
            >
              <ServiceStoreImage
                src={locale === 'de' ? AppleStoreSvgDe : AppleStoreSvgEn}
                alt="apple-store-badge"
                loading="lazy"
              />
            </a>
          </ServiceStoreImageWrapper>
        </TextContainer>
        <QRCodeContainer>
          <QrCodeWrapper>
            <StyledButterfly size={48} />
            <StyledQRCode
              src={QrCodeSvg}
              alt="QR Code - mobile apps"
              loading="lazy"
            />
          </QrCodeWrapper>
        </QRCodeContainer>
      </LeftContainer>
      <RightContainer />
    </StyledContainer>
  );
};
