import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { CardInspire } from '@xing-com/card';
import {
  mediaTiny,
  mediaTopBarVisible,
  mediaNavExtended,
  mediaConfined,
} from '@xing-com/layout-tokens';
import {
  spaceL,
  spaceXXL,
  space4XL,
  xdlPaletteLime09,
  xdlColorTextOnDark,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

import banner from './assets/banner.svg';
import deepDive from './assets/deep-dive.jpg';

export const StyledSection = styled.section`
  display: flex;
  gap: ${spaceL};
  margin-bottom: ${spaceXXL};
  margin-top: ${spaceXXL};

  @media ${mediaConfined} {
    margin-top: ${space4XL};
    margin-bottom: ${space4XL};
  }

  & > *:first-child {
    flex-grow: 1;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  gap: ${spaceL};

  & > *:first-child {
    flex-grow: 1;
  }

  & > *:first-child,
  & > *:nth-child(3) {
    width: 100%;
  }

  &:first-child {
    margin-bottom: ${spaceL};
  }

  @media ${mediaTiny} {
    flex-direction: column;
  }

  @media ${mediaTopBarVisible} {
    & > * {
      min-width: calc(50% - 8px);
    }
  }
`;

export const StyledImageCard = styled(CardInspire)`
  background: url(${deepDive}) no-repeat center center;
  background-size: cover;
`;

export const StyledImageCardWrapper = styled.div`
  display: none;

  @media ${mediaNavExtended} {
    display: flex;
    flex-grow: 1;
    width: 368px;

    & > * {
      flex-grow: 1;
    }
  }
`;

export const StyledIllustrationCard = styled(CardInspire)`
  justify-content: center;
  align-items: center;
  color: ${xdlColorTextOnDark};
  padding: ${spaceL};
`;

export const StyledDesktopOnlyWrapper = styled.div`
  & > * {
    display: inline;
  }

  @media ${mediaTopBarVisible} {
    display: none;
  }
`;

export const StyledProfileCard = styled(CardInspire)`
  background: url(${banner}) no-repeat center center ${xdlPaletteLime09};
  background-size: cover;
`;

export const StyledBodyCopy = styled(BodyCopy)`
  max-width: 450px;
  margin-bottom: ${spaceXXL};
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: ${spaceL};
  flex-wrap: wrap;
`;

export const StyledButton = styled(Button)`
  @media ${mediaTiny} {
    width: 100%;
  }
`;
