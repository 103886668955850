import styled from 'styled-components';

import { CarouselSection } from '@xing-com/crate-x4e-carousel-section';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceXXL, space4XL } from '@xing-com/tokens';

export const StyledCarouselSection = styled(CarouselSection)`
  margin: 0 auto;
  margin-top: ${spaceXXL};
  margin-bottom: ${spaceXXL};
  max-width: 1520px;
  width: 100%;

  @media ${mediaConfined} {
    margin-top: ${space4XL};
    margin-bottom: ${space4XL};
  }
`;
