import * as React from 'react';

import * as Styled from './marker.styles';
import type { MarkerProps } from './marker.types';

export const Marker = React.forwardRef<HTMLSpanElement, MarkerProps>(
  (
    {
      children,
      className,
      ellipsis,
      icon: Icon,
      iconAfter,
      maxCharacters = 21,
      variant = 'default',
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const isInteractive = !!props.onClick;
    const fullText = typeof children === 'string' ? children : undefined;

    return (
      <Styled.Marker
        $ellipsis={ellipsis}
        $variant={variant}
        className={className}
        data-xds="Marker"
        ref={forwardedRef}
        role={isInteractive ? 'button' : 'status'}
        tabIndex={isInteractive ? 0 : undefined}
        {...props}
      >
        {Icon && (
          <Styled.IconContainer $iconAfter={iconAfter} $variant={variant}>
            <Icon width="14" height="14" aria-hidden="true" />
          </Styled.IconContainer>
        )}
        <Styled.Text
          $ellipsis={ellipsis}
          $iconAfter={iconAfter}
          $maxCharacters={String(maxCharacters)}
          $variant={variant}
          forwardedAs="span"
          lineHeight="dense"
          noMargin
          size="small"
          title={ellipsis && fullText ? fullText : undefined}
        >
          {children}
        </Styled.Text>
      </Styled.Marker>
    );
  }
);

Marker.displayName = 'Marker';
