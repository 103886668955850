import { Redirect, useLocation } from '@reach/router';
import { useEffect } from 'react';

import { DisplayAdsBiddingScript } from '@xing-com/crate-ads-display-ads';
import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { FindJobsSearchBar } from '@xing-com/crate-jobs-pages-find-jobs-search-bar';
import { Head } from '@xing-com/crate-startpage-logged-out-head';
import { JobsSection } from '@xing-com/crate-startpage-logged-out-jobs-section-package';
import { NativeAppsSection } from '@xing-com/crate-startpage-logged-out-native-apps-section';
import { SeoSection } from '@xing-com/crate-startpage-logged-out-seo-section';
import {
  Container as LayoutPrimitivesContainer,
  Section,
} from '@xing-com/layout-primitives';

import { AdSection } from './ad-section';
import { CompaniesSection } from './companies-section';
import { DeepDiveSection } from './deep-dive-section';
import { RecruitersSection } from './recruiters-section';
import { RegisterSection } from './register-section';
import { SearchInspirationSection } from './search-inspiration-section';
import { StyledAiSearchBanner } from './startpage-logged-out.styles';
import {
  useTrackPageView,
  useTrackClickStoreButton,
  useTrackClickSerpCategory,
} from './tracking';

const rootLanguages = ['de', 'en', 'es', 'fr', 'it'];

const checkLanguageRootPaths = (pathname: string): string | undefined => {
  return rootLanguages.find((language) => {
    return [`/${language}`, `/${language}/`].includes(pathname);
  });
};

export const StartPageLoggedOut: React.FC = () => {
  const { isLoggedOut } = useLoginState();
  const trackClickStoreButton = useTrackClickStoreButton();
  const trackClickSerpCategory = useTrackClickSerpCategory();
  const { pathname } = useLocation();
  const trackPageView = useTrackPageView();

  useEffect(() => {
    if (isLoggedOut) {
      trackPageView();
    }
  }, [isLoggedOut, trackPageView]);

  useEffect(() => {
    const newLocale = checkLanguageRootPaths(pathname);

    if (newLocale && rootLanguages.includes(newLocale)) {
      const now = new Date();
      const oneYearFromNowDate = new Date(
        now.setFullYear(now.getFullYear() + 1)
      );

      window.document.cookie = `language=${newLocale}; domain=.xing.com; expires=${oneYearFromNowDate.toUTCString()}`;
      window.document.cookie = `language=${newLocale}; expires=${oneYearFromNowDate.toUTCString()}`;
    }
  }, [pathname]);

  if (!isLoggedOut) {
    return <Redirect to="/jobs/find" />;
  } else {
    return (
      <>
        <DisplayAdsBiddingScript />
        <Head page="startPage" />
        <StyledAiSearchBanner
          propActionOrigin="losp_job_search"
          scOParam="jobs_ai_assisted_search"
          nwtTracking={{
            page: 'welcome/start/index',
            sentBy: 'welcome',
          }}
        />
        <FindJobsSearchBar
          withFilters
          withTeaser
          spacious
          isStartPage
          withKeywords
        />
        <Section noBlockPadding>
          <JobsSection />
        </Section>
        <Section noBlockPadding>
          <LayoutPrimitivesContainer variant="expanded">
            <AdSection placement="top" />
            <SearchInspirationSection />
          </LayoutPrimitivesContainer>
        </Section>
        <CompaniesSection />
        <Section noBlockPadding>
          <LayoutPrimitivesContainer variant="expanded">
            <DeepDiveSection />
          </LayoutPrimitivesContainer>
        </Section>
        <RecruitersSection />
        <Section noBlockPadding>
          <LayoutPrimitivesContainer variant="expanded">
            <AdSection placement="bottom" />
            <NativeAppsSection trackClickStoreButton={trackClickStoreButton} />
            <SeoSection trackClickSerpCategory={trackClickSerpCategory} />
            <RegisterSection />
          </LayoutPrimitivesContainer>
        </Section>
      </>
    );
  }
};
