import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { CardInspire } from '@xing-com/card';
import {
  mediaTiny,
  mediaSmallOnly,
  mediaConfined,
  mediaWideNavless,
  mediaNavExtended,
} from '@xing-com/layout-tokens';
import { ModalPage } from '@xing-com/modal-page';
import {
  spaceS,
  spaceM,
  spaceL,
  spaceXL,
  space4XL,
  spaceXXL,
} from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

import inspire from './assets/inspire.jpg';

export const StyledButton = styled(Button)`
  margin-top: ${spaceXL};
  width: auto;

  @media ${mediaTiny} {
    width: 100%;
  }

  @media ${mediaNavExtended} {
    width: 100%;
  }
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${spaceM};
`;

export const StyledTags = styled.div`
  display: flex;
  gap: ${spaceS};
  flex-wrap: wrap;
  margin-top: ${spaceXL};
`;

export const StyledInspirationalCards = styled.div`
  display: grid;
  gap: ${spaceL};
  margin-top: ${spaceXXL};
  margin-bottom: ${spaceXXL};

  @media ${mediaConfined} {
    margin-top: ${space4XL};
    margin-bottom: ${space4XL};
  }

  @media ${mediaNavExtended} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledImageCard = styled(CardInspire)`
  background: url(${inspire}) no-repeat center center;
  background-size: cover;

  @media ${mediaTiny} {
    min-height: 180px;
  }

  @media ${mediaSmallOnly} {
    min-height: 220px;
  }

  @media ${mediaConfined} {
    min-height: 252px;
  }

  @media ${mediaWideNavless} {
    min-height: 100%;
  }
`;

export const StyledIllustrationCard = styled(CardInspire)`
  & svg {
    align-self: center;
  }
`;

export const StyledPreferencesCard = styled(CardInspire)`
  height: 100%;

  & > * {
    height: 100%;
    justify-content: space-between;
  }
`;

export const StyledModalPage = styled(ModalPage)`
  padding: ${spaceL};
`;

export const StyledColumnLeft = styled.div`
  display: grid;
  gap: ${spaceL};

  @media ${mediaWideNavless} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledColumnRight = styled.div`
  display: grid;
  gap: ${spaceL};
  grid-template-columns: 1fr 1fr;

  @media ${mediaTiny} {
    grid-template-columns: 1fr;
  }
`;

export const StyledInnerColumn = styled.div`
  display: grid;
  gap: ${spaceL};
`;
