import styled from 'styled-components';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { NoSSR } from '@xing-com/crate-utils-no-ssr';
import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { scale190, scale130 } from '@xing-com/tokens';

import { useAdRefresh } from '../use-ad-refresh';

import {
  DisplayAdStartpageLeaderboard,
  DisplayAdStartpageRectangle02,
  SectionCol,
} from '.';

const AdWrapper = styled.div`
  width: 300px;
  height: 250px;
  position: relative;
  z-index: 1;
  margin-top: ${scale130};
  margin-bottom: ${scale130};

  @media ${mediaConfined} {
    margin-top: ${scale190};
    margin-bottom: 0;
    width: 728px;
    height: 90px;
  }
`;

export const DisplayAdStartpageSlot02: React.FC = () => {
  const isSmallScreen = useMatchMedia(mediaSmallOrTiny);

  const { refreshMobile, refreshDesktop } = useAdRefresh({
    isSmallScreen,
    slots: {
      mobile: 'rectangle02',
      desktop: 'leaderboard',
    },
  });

  return (
    <NoSSR>
      <SectionCol>
        <AdWrapper>
          {isSmallScreen ? (
            <DisplayAdStartpageRectangle02 refresh={refreshMobile} />
          ) : (
            <DisplayAdStartpageLeaderboard refresh={refreshDesktop} />
          )}
        </AdWrapper>
      </SectionCol>
    </NoSSR>
  );
};
